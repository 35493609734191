import React from "react";
import Logo from '../../assets/images/logo.png'
import { Link } from "react-router-dom";
import { appConfig } from "../../appConfig";
import { footerData } from "./footerData";
import ReactWhatsappButton from "react-whatsapp-button";
import PAY from "../../assets/images/banner/pay.png";
import ICON from "../../assets/images/banner/icpnheader.png";
import TPP from "../../assets/images/banner/tpp.png";
import IATA from "../../assets/images/banner/iata.png";


const Footer=props=>{

    const headRoute = appConfig.headRoute

    return(
        
        <div className="bg-secondary flex min-h-[100vh] md:xl:min-h-[60vh] 2xl:min-h-[40vh] lg:justify-center lg:items-center">
    <div className="w-full ">
        <div className="mb-10 max-md:py-10 mt-6">
            {/* Logo Image */}
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center mb-4 md:px-12">
              {/* eDreamsales Logo */}
              <div className="flex items-center gap-4">
                <img src={ICON} alt="eDreamsales Logo" className="h-[90px] mb-4" />
                
              </div>


               

              {/* Trustpilot Review Button */}
              <a src={TPP} href="https://uk.trustpilot.com/review/yourbusiness" target="_blank" rel="noopener noreferrer" className="flex items-center">
              <img src={TPP} alt="eDreamsales Logo" className="h-[90px] mb-4" />
              </a>
            </div>
            
            

            {/* Copyright and Other Information */}
            
            <hr className="my-7 mx-[5px] border-t-2 border-white w-[100%]"/>

            {/* Quick Links Section */}
          <div className="flex flex-wrap justify-center gap-8 mb-8">
            <a href="/home" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">Home</a>
            <a href="/traveltips" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">TravelTips</a>
            <a href="/tc" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">Terms & Conditions</a>
            <a href="/privacypolicy" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">Privacy Policy</a>
            <a href="/aboutus" className="text-white text-lg hover:text-gray-300 transition-colors duration-300">FAQ</a>
          </div>

          {/* Address and Logo Section */}
          <div className="flex items-center justify-between text-white mb-8">
            {/* Address Section */}
            
            
            <div className="text-center flex-1">
              <p className="text-lg font-bold mb-2">Our Address</p>
              <p className="text-md">eDream Lounge LTD,</p>
              <p className="text-md">Companies house,</p>
              <p className="text-md">Cardiff CF14 8LH </p>
              <p className="text-md">support@travelbudget.co.uk</p>
            </div>

        
            
          </div>
          
            
            
            <ReactWhatsappButton
                countryCode="44"
                phoneNumber="7365892753"
            />

<hr className="my-7 mx-[5px] border-t-2 border-white w-[100%]"/>


            {/* Payment Logos */}
            <div className="flex flex-col items-center mt-8">
            {/* Payment Logos */}
            <div className="flex items-center mb-4">
              <img src={PAY} alt="Payment Logos" className="h-12" />
            </div>
            
            {/* Copyright Text */}
            <p className="text-white text-sm lg:text-md text-center">
              Copyright © 2024 Travel Budget. All rights reserved.
            </p>
        </div>
        </div>
    </div>
</div>

    )
}
export default Footer